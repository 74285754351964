import React from 'react'
import { graphql } from 'gatsby'
import { css } from 'linaria'
import Img from 'gatsby-image'
import { Meta } from 'react-head'

import Layout from '../components/Layout'

import FeaturedCaseStudy from '../sections/FeaturedCaseStudy'
import Testimonials from '../sections/Testimonials'
import ClientsOnMap from '../sections/ClientsOnMap'
import LatestPosts from '../sections/LatestPosts'

import { HeroHeading, Subheading, Lead } from '../components/TextElements'

import Button from 'components/Button'

import FooterCTA from 'components/Footer/CTA'
import Section from 'components/Section'
import SectionContent from 'components/Section/Content'

import Hero from '../elements/Hero'
import Products from '../elements/Products'
import IconBox from '../elements/IconBox'
import TechnologiesList from '../elements/TechnologiesList'

import rhythm from '../utils/rhythm'
import breakpoints from '../utils/tokens/breakpoints'

const heroContent = (
  <>
    <Subheading>JAMstack Developers for hire</Subheading>
    <HeroHeading>
      Meet faster,
      <br />
      more secure web.
    </HeroHeading>
    <Lead>
      Let us maximize your business potential with the latest and innovative
      technologies.
    </Lead>
    <Button to="/estimate-project/">Get started for Free</Button>
    <p
      className={css`
        ${{ marginTop: rhythm(1) }}
      `}
    >
      Get one-to-one consultation in less than 24h.
    </p>
  </>
)

const heroParticles = data => (
  <>
    <Img
      fixed={data.p6.childImageSharp.fixed}
      className={css`
        ${{
          bottom: '-42%',
          right: '10%',
          transform: 'rotate(-159deg)',
          [`@media (max-width: ${breakpoints.md})`]: {
            display: 'none !important'
          }
        }};
      `}
      style={{ position: 'absolute' }}
    />
    <Img
      fixed={data.p30.childImageSharp.fixed}
      className={css`
        ${{
          bottom: '-11%',
          right: '1%',
          [`@media (max-width: ${breakpoints.md})`]: {
            display: 'none !important'
          }
        }};
      `}
      style={{ position: 'absolute' }}
    />
    <Img
      fixed={data.p9.childImageSharp.fixed}
      className={css`
        ${{
          bottom: '-15%',
          right: '20%',
          transform: 'rotate(-80deg)',
          [`@media (max-width: ${breakpoints.md})`]: {
            display: 'none !important'
          }
        }};
      `}
      style={{ position: 'absolute' }}
    />
    <Img
      fixed={data.p16.childImageSharp.fixed}
      className={css`
        ${{
          bottom: '-33%',
          right: '-4%',
          transform: 'rotate(34deg)',
          [`@media (max-width: ${breakpoints.md})`]: {
            display: 'none !important'
          }
        }};
      `}
      style={{ position: 'absolute' }}
    />
  </>
)

const Page = ({ location, data }) => (
  <Layout
    location={location}
    title="Bejamas: JAMstack developers for hire."
    description="Lightning fast, secure, and SEO-friendly websites, web apps, and e-commerce. If that’s what you’re looking for - we’re the guy."
    preLinksComponent={<FooterCTA />}
    jsonLD={`
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Bejamas",
        "description": "JAMstack Developers for hire. A Web Dev shop focused on helping agencies and companies with serverless web, Static Site Generators & JAMstack tools.",
        "image": "https://bejamas.io/icons/icon-512x512.png",
        "logo": "https://bejamas.io/icons/icon-512x512.png",
        "url": "https://bejamas.io/",
        "telephone": "+48690442069",
        "sameAs": ["https://twitter.com/bejamas_io","https://www.linkedin.com/company/bejamas/","https://www.facebook.com/bejamas.io"],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Szczytnicka 11",
          "addressLocality": "Wrocław",
          "postalCode": "50-382",
          "addressCountry": "Poland"
        }
      }
    `}
  >
    <Hero
      content={heroContent}
      image={data.hero}
      particles={heroParticles(data)}
    />
    <FeaturedCaseStudy
      image={data.seomonitor}
      itemCount={data.caseStudiesCount.pageInfo.itemCount}
    />

    <Section
      variant="lightGray"
      header={
        <>
          <Subheading>Sites with benefits</Subheading>
          <h2>How JAMstack supports your business</h2>
          <Lead>
            We use the power of JAMstack and server-side rendering to ship value
            to our customers.
          </Lead>
        </>
      }
    >
      <SectionContent
        rowGap="2"
        columnGap={`calc(6vw + ${rhythm(2)})`}
        className={css`
          ${{
            gridTemplateColumns: `repeat(auto-fill, minmax(${rhythm(12)}, 1fr))`
          }};
        `}
      >
        <IconBox withNumber>
          <Img fixed={data.swift.childImageSharp.fixed} alt="Swift" />
          <h3>Swift</h3>
          <p>
            We don’t use databases, so there’s no time-consuming multiple
            queries. Static sites are even up to 10x faster, with benefits for
            the UX and SERP rankings.
          </p>
        </IconBox>
        <IconBox withNumber>
          <Img fixed={data.safe.childImageSharp.fixed} alt="Safe" />
          <h3>Safe</h3>
          <p>
            73.2% of the most popular WordPress installations are opened to
            vulnerabilities. Statically rendered websites with no plugins are a
            fortress and let you save money on security.
          </p>
        </IconBox>
        <IconBox withNumber>
          <Img fixed={data.stable.childImageSharp.fixed} alt="Stable" />
          <h3>Stable</h3>
          <p>
            Traffic bigger than usual? Not an issue with the JAMstack sites -
            you host your websites’ static files in a multi-cloud environment on
            a CDN with no traffic limitations.
          </p>
        </IconBox>
        <IconBox withNumber>
          <Img fixed={data.scalable.childImageSharp.fixed} alt="Scalable" />
          <h3>Scalable</h3>
          <p>
            Implementing new features to your project? Serverless architecture
            scales automatically as your usage or user base extends, so you can
            scale your app or site without hassle.
          </p>
        </IconBox>
      </SectionContent>
    </Section>

    <Testimonials
      image={data.spreadingGoodWord}
      authorImage={data.simonJones}
    />

    <Section
      variant="lightGray"
      header={
        <>
          <Subheading>Technologies we use</Subheading>
          <h2>Beautifying the web</h2>
          <Lead>
            Every project is a new story, yet some things stay the same. We pick
            a dedicated set of tools for each one, for the best performance
            possible.
          </Lead>
        </>
      }
    >
      <TechnologiesList />
    </Section>

    <Section
      header={
        <>
          <Subheading>Products we craft</Subheading>
          <h2>Perfect for SMBs and enterprise</h2>
          <Lead>
            Best things since sliced bread. Shaped with diligence and the
            highest software-craftsmanship.
          </Lead>
        </>
      }
    >
      <SectionContent columnGap="2" rowGap="2">
        <Products
          jamstackContent={
            <>
              Get new customers with a faster website with higher SEO chances.
              We build them with proven technologies: GatsbyJS, Hugo, Netlify,
              and many others.
            </>
          }
          pwaContent={
            <>
              Save time and money with a progressive web app instead of native
              apps for multiple platforms. JAMstack PWA means great performance
              and low hosting costs.
            </>
          }
        />
      </SectionContent>
    </Section>
    <ClientsOnMap image={data.map} />
    <LatestPosts particles={[data.p30, data.p22, data.p28]} />
  </Layout>
)

export default Page

export const query = graphql`
  query HomePageQuery {
    hero: file(relativePath: { regex: "/img/home/" }, name: { eq: "hero" }) {
      childImageSharp {
        fluid(maxWidth: 676, traceSVG: { color: "#0a0116" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    p6: file(relativePath: { regex: "/img/particles/" }, name: { eq: "6" }) {
      childImageSharp {
        fixed(width: 160) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    p30: file(relativePath: { regex: "/img/particles/" }, name: { eq: "30" }) {
      childImageSharp {
        fixed(width: 140) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    p9: file(relativePath: { regex: "/img/particles/" }, name: { eq: "9" }) {
      childImageSharp {
        fixed(width: 230, traceSVG: { color: "#0a0116" }) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    p16: file(relativePath: { regex: "/img/particles/" }, name: { eq: "16" }) {
      childImageSharp {
        fixed(width: 45, traceSVG: { color: "#0a0116" }) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    p22: file(relativePath: { regex: "/img/particles/" }, name: { eq: "22" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    p28: file(relativePath: { regex: "/img/particles/" }, name: { eq: "28" }) {
      childImageSharp {
        fixed(width: 64) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    seomonitor: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "seomonitor-cs" }
    ) {
      childImageSharp {
        fluid(maxWidth: 290, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    caseStudiesCount: allMarkdownRemark(
      filter: { frontmatter: { category: { regex: "/case-studies/" } } }
    ) {
      pageInfo {
        itemCount
      }
    }
    swift: file(
      relativePath: { regex: "/img/home/" }
      name: { eq: "icon-swift@2x" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    safe: file(
      relativePath: { regex: "/img/home/" }
      name: { eq: "icon-safe@2x" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    stable: file(
      relativePath: { regex: "/img/home/" }
      name: { eq: "icon-stable@2x" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    scalable: file(
      relativePath: { regex: "/img/home/" }
      name: { eq: "icon-scalable@2x" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    spreadingGoodWord: file(
      relativePath: { regex: "/img/home/" }
      name: { eq: "testimonials-bg-2" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, traceSVG: { color: "#090017" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    simonJones: file(
      relativePath: { regex: "/img/" }
      name: { eq: "simon-jones" }
    ) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    map: file(
      relativePath: { regex: "/img/home/" }
      name: { eq: "map-filled" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 80, traceSVG: { color: "#0b0112" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
