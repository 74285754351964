import React from 'react'
import { css } from 'linaria'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Section from 'components/Section'
import rhythm from '../../utils/rhythm'
import SecondaryButton from 'components/Button/Secondary'
import breakpoints from '../../utils/tokens/breakpoints'

import { ReactComponent as Star } from '../../assets/svg/icons/star.svg'

const content = css`
  h3 {
    font-size: 1.5rem;
  }

  @media (min-width: ${breakpoints.md}) {
    h3 {
      font-size: 2.25rem;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    column-gap: ${rhythm(4)};
    align-items: center;
    padding-left: ${rhythm(2)};
    grid-template-columns: auto 40%;

    h3 {
      font-size: 2.25rem;
    }
  }
`

const stars = css`
  display: flex;
  margin: ${rhythm(2)} 0 ${rhythm(2)};

  svg + svg {
    margin-left: ${rhythm(1 / 2)};
  }
`

const author = css`
  display: flex;
`

const avatar = css`
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  background: #000;
  border-radius: 100%;
  margin-right: ${rhythm(1 / 2)};
`

const imageClass = css`
  @media (max-width: ${breakpoints.lg}) {
    display: none !important;
  }
`

const Testimonials = ({ image, authorImage }) => (
  <Section>
    <div className={content}>
      <div>
        <h3>
          „Bejamas exceeded our expectations and I wouldn’t hesitate to work
          with them again. The JAMstack combination of Gatsby, Sanity, and
          Netlify is cool. It’s modern and robust, but also a fast and effective
          solution for all of our needs.”
        </h3>
        <div className={stars}>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </div>
        <div className={author}>
          <Img
            fixed={authorImage.childImageSharp.fixed}
            alt="Simon Jones Photo"
            className={avatar}
          />
          <p>
            Simon Jones <br />
            VP of Engineering, Just Technologies AS
            <br />
            <SecondaryButton to="/blog/case-study-just-technologies/">
              Read case study »
            </SecondaryButton>
          </p>
        </div>
      </div>
      <Img
        fluid={image.childImageSharp.fluid}
        alt="Spreading the good word"
        className={imageClass}
        style={{ maxWidth: image.childImageSharp.fluid.presentationWidth }}
      />
    </div>
  </Section>
)

export default Testimonials
