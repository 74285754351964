import React from 'react'
import { css } from 'linaria'
import Img from 'gatsby-image'
import rhythm from '../../utils/rhythm'

import Subheading from 'components/TextElements/Subheading'
import Lead from 'components/TextElements/Lead'
import Section from 'components/Section'

import breakpoints from '../../utils/tokens/breakpoints'

const map = css`
  max-width: 64rem;
  margin: ${rhythm(3 / 2)} auto 0;

  @media (min-width: ${breakpoints.md}) {
    margin: ${rhythm(3)} auto 0;
  }
`

const center = css`
  text-align: center;
`

const ClientsOnMap = ({ image }) => (
  <Section
    variant='lightGray'
    className={center}
    header={
      <>
        <Subheading>Bettering the web for clients from</Subheading>
        <h2>14 countries, 5 continents</h2>
        <Lead>
          We’re an international-ready team.
          <br />
          Worked in sync with customers in 7 different time-zones.
        </Lead>
      </>
    }
  >
    <Img fluid={image.childImageSharp.fluid} alt='map' className={map} />
  </Section>
)

export default ClientsOnMap
